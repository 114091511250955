<template>
    <LayoutBase @keyup.enter="onResetPassword">
        <template #title>{{ t("newPassword") }}</template>
        <template #description />

        <AuthInput
            id="password"
            v-model="password"
            :label="$t('common.password')"
            with-tooltip
            type="password"
        />

        <AuthInput
            id="password-confirmation"
            v-model="passwordConfirmation"
            :label="t('passwordConfirmation')"
            type="password"
        />

        <div class="row row_top-m">
            <UiButton
                :disabled="!isChange || isSending"
                :class="$style['full-width']"
                size="md"
                @click="onResetPassword"
            >
                {{ t("resetPassword") }}
            </UiButton>
        </div>

        <div v-if="errorMessage" class="row row_top-m">
            <UiAlert :text="errorTranslate" :closable="false" type="danger" />
        </div>
    </LayoutBase>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { ref, computed, watch } from 'vue';
import LayoutBase from '@/components/layouts/LayoutBase.vue';
import AuthInput from '@/components/common/AuthInput.vue';
import { useRoute, useRouter } from 'vue-router';
import { usePasswordStore } from '@/stores/password';
import { useReCaptcha } from '@/plugins/reCaptcha';
import { useSending } from '@/composables/sending';

const router = useRouter();
const route = useRoute();
const { t } = useI18n();
const { reCaptcha } = useReCaptcha();
const {isSending, setSending} = useSending();

const { fetchResetPassword } = usePasswordStore();

const password = ref('');
const passwordConfirmation = ref('');
const errorMessage = ref<string>('');
const confirmationToken = ref(route.params.confirmationToken as string);

const allowedErrors = new Map<string, string>([
    ['FirstPassword is a required field', t('error.emptyFirstPassword')],
    ['SecondPassword is a required field', t('error.emptySecondPassword')],
    ['FirstPassword is invalid - it must contains 6-64 symbols including latin letters in upper or lower case, digits or special symbols', t('error.invalidFirstPassword')],
    ['SecondPassword is invalid - it must contains 6-64 symbols including latin letters in upper or lower case, digits or special symbols', t('error.invalidFirstPassword')],
    ['SecondPassword must be equal to FirstPassword', t('error.passwordDontMath')],
    ['token not found', t('error.tokenNotFound')],
    ['disable for service account', t('error.disableServiceAccount')],
])

const errorTranslate = computed((): string => {
    return allowedErrors.get(errorMessage.value) ?? t('error.undefinedError')
});

const isChange = computed((): boolean => {
    return password.value !== '' && passwordConfirmation.value !== ''
});

watch([password, passwordConfirmation], () => {
    errorMessage.value = ''
})

const onResetPassword = () => {
    if (!isChange.value) {
        return
    }

    setSending(true)
    reCaptcha.execute('reset_password').then(function (token: string) {
        fetchResetPassword(
            password.value,
            passwordConfirmation.value,
            confirmationToken.value,
            token
        )
            .then(({ error }) => {
                errorMessage.value = ''

                if (error != null) {
                    errorMessage.value = error.messages[0];

                    return;
                }

                router.push({ name: 'login' });
            })
            .finally(() => setSending(false));
    })
};
</script>

<style lang="less" module>
.full-width {
  width: 100%;
}

.link-box {
  text-align: center;
}
</style>

<i18n locale="ru_RU" lang="json">
{
    "newPassword": "Новый пароль",
    "passwordConfirmation": "Подтверждение пароля",
    "resetPassword": "Сбросить пароль",
    "error": {
        "emptyFirstPassword": "Введите новый пароль",
        "emptySecondPassword": "Повторите новый пароль",
        "invalidFirstPassword": "Неверный формат нового пароля",
        "passwordDontMath": "Пароли не совпадают",
        "tokenNotFound": "Неверный токен смены пароля",
        "disableServiceAccount": "Пользователь тех. поддержки не может менять пароль",
        "undefinedError": "Неизвестная ошибка"
    }
}
</i18n>

<i18n locale="en_GB" lang="json">
{
    "newPassword": "New password",
    "passwordConfirmation": "Confirm the password",
    "resetPassword": "Reset password",
    "error": {
        "emptyFirstPassword": "Enter a new password",
        "emptySecondPassword": "Repeat new password",
        "invalidFirstPassword": "Invalid new password format",
        "passwordDontMath": "Passwords do not match",
        "tokenNotFound": "Invalid password change token",
        "disableServiceAccount": "User of tech. support cannot change the password",
        "undefinedError": "Unknown error"
    }
}
</i18n>

<i18n locale="es_ES" lang="json">
{
    "newPassword": "Nueva contraseña",
    "passwordConfirmation": "Confirmar contraseña",
    "resetPassword": "Restablecer contraseña",
    "error": {
        "emptyFirstPassword": "Introduzca una nueva contraseña",
        "emptySecondPassword": "Repita la nueva contraseña",
        "invalidFirstPassword": "Nuevo formato de contraseña no válido",
        "passwordDontMath": "Las contraseñas no coinciden",
        "tokenNotFound": "Token de cambio de contraseña no válido",
        "disableServiceAccount": "Usuario de tecnología. soporte no puede cambiar la contraseña",
        "undefinedError": "Error desconocido"
    }
}
</i18n>

import { createRouter, createWebHistory } from 'vue-router';
import type { RouteRecordRaw } from 'vue-router';
import { useMeStore } from '@/stores/me';
import { useReCaptcha } from '@/plugins/reCaptcha';

const routes = [
    {
        path: '/',
        name: 'main',
        component: () => import('../views/MainView.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/LoginView.vue'),
        meta: { requiresAuth: false },
        props: { successMessage: '' },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: { requiresAuth: true },
    },
    {
        path: '/reminder',
        name: 'reminder',
        component: () => import('../views/ReminderView.vue'),
        meta: { requiresAuth: false },
    },
    {
        path: '/reset/:confirmationToken',
        name: 'reset',
        component: () => import('../views/ResetView.vue'),
        meta: { requiresAuth: false },
    },
    {
        path: '/password-change',
        name: 'password-change',
        component: () => import('../views/PasswordChangeView.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/email-change',
        name: 'email-change',
        component: () => import('../views/EmailChangeView.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/email-confirm',
        name: 'email-confirm',
        component: () => import('../views/EmailConfirmView.vue'),
    },
    {
        path: '/:pathMatch(.*)*',
        name: '404',
        component: () => import('../views/Error404View.vue'),
    },
] as RouteRecordRaw[];

const router = createRouter({
    history: createWebHistory('/'),
    routes,
});

router.beforeEach(async (to) => {
    const { reCaptcha, load } = useReCaptcha()
    const store = useMeStore()

    await load()

    if (store.isAuthorized === undefined || (store.isAuthorized && store.user === null)) {
        await store.me()
    }

    if (to.name === 'logout') {
        if (store.isAuthorized) {
            await reCaptcha.execute('logout')
                .then(function (token: string) {
                    return store.logout(token)
                })
        }

        return { name: 'login', query: to.query };
    }

    if (!store.isAuthorized && to.query['fast-login']) {
        const fastLoginToken = to.query['fast-login'].toString()
        const recaptcha = await reCaptcha.execute('fastLogin')
        if (recaptcha) {
            await store.fastLogin(fastLoginToken, recaptcha)
        }
    }

    const route = { query: to.query };
    if (to.meta.requiresAuth === true && !store.isAuthorized) {
        return { name: 'login', ...route };
    } else if (to.meta.requiresAuth === false && store.isAuthorized) {
        return { name: 'main', ...route };
    }
});

export default router;

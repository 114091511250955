import type { App } from 'vue';
import * as SentryPlugin from '@sentry/vue';
import type { Router } from 'vue-router';

const replaysSessionSampleRate = 0.1
const replaysOnErrorSampleRate = 1.0

export interface SentryOptions {
    dsn: string;
    vueRouter: Router;
}

export const Sentry = {
    install(app: App, options: SentryOptions) {
        if (!options.dsn) {
            return
        }

        SentryPlugin.init({
            app,
            dsn: options.dsn,
            integrations: [
                new SentryPlugin.BrowserTracing({
                    routingInstrumentation: SentryPlugin.vueRouterInstrumentation(options.vueRouter),
                }),
                new SentryPlugin.Replay(),
            ],
            sendDefaultPii: true,
            replaysSessionSampleRate,
            replaysOnErrorSampleRate,
            ignoreErrors: [
                /^AxiosError:/,
                /^Non-Error promise rejection captured with value/,
            ],
        })
    },
}

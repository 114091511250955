<template>
    <LayoutError :img-src="Image500" :title="t('title')" error-code="500">
        <p>{{ t("description.first") }}</p>
        <p>
            {{ t("description.second") }}
            <UiLink @click="toMain">{{ t("description.mainPage") }}</UiLink>
        </p>
    </LayoutError>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import LayoutError from '@/components/layouts/LayoutError.vue';
import Image500 from '../assets/img/500-decor.svg';
import { useRouter } from 'vue-router';
import { useError } from '@/composables/error';

const { t } = useI18n();
const { push } = useRouter();
const { resetCode } = useError();

const toMain = () => {
    resetCode()
    push({ name: 'main' })
}

</script>

<i18n locale="ru_RU" lang="json">
{
  "title": "Внутренняя ошибка",
  "description": {
    "first": "Произошла непредвиденная ошибка. Мы уже уведомлены об инциденте и в ближайшее время все починим.",
    "second": "Попробуйте начать с",
    "mainPage": "главной страницы."
  }
}
</i18n>

<i18n locale="en_GB" lang="json">
{
  "title": "Internal error",
  "description": {
    "first": "An unexpected error has occurred. We have already been notified of the incident and will fix it shortly.",
    "second": "Try to start with",
    "mainPage": "the main page."
  }
}
</i18n>

<i18n locale="es_ES" lang="json">
{
  "title": "Error interno",
  "description": {
    "first": "Ha ocurrido un error imprevisto. Ya hemos notificado el incidente y lo solucionaremos lo antes posible.",
    "second": "Intenta con la",
    "mainPage": "página principal."
  }
}
</i18n>

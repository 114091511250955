import type {
    ClientInterface, ConfirmEmailRequest,
    RequestPasswordRequest,
    ResetPasswordRequest,
    SuccessResponse,
} from '@/api/public/interface';
import { handleError } from '@/api/response';
import type { Response } from '@/api/response';
import client from '@/api/client';

class Client implements ClientInterface {
    requestPassword(
        request: RequestPasswordRequest
    ): Promise<Response<SuccessResponse>> {
        return new Promise<Response<SuccessResponse>>((resolve, reject) => {
            client
                .post<SuccessResponse>(
                    '/api/v1/public/request-password', 
                    {
                        email: request.email,
                    },
                    {
                        headers: {
                            recaptcha: request.recaptcha,
                        },
                    }
                )
                .then(function (response) {
                    resolve({
                        data: response.data,
                    });
                })
                .catch(function (err) {
                    handleError(err, resolve, reject);
                });
        });
    }

    resetPassword(
        request: ResetPasswordRequest
    ): Promise<Response<SuccessResponse>> {
        return new Promise<Response<SuccessResponse>>((resolve, reject) => {
            client
                .post<SuccessResponse>(
                    '/api/v1/public/reset-password', 
                    {
                        first_password: request.firstPassword,
                        second_password: request.secondPassword,
                        confirmation_token: request.confirmationToken,
                    },
                    {
                        headers: {
                            recaptcha: request.recaptcha,
                        },
                    }
                )
                .then(function (response) {
                    resolve({
                        data: response.data,
                    });
                })
                .catch(function (err) {
                    handleError(err, resolve, reject);
                });
        });
    }

    confirmEmail(
        request: ConfirmEmailRequest
    ): Promise<Response<SuccessResponse>> {
        return new Promise(((resolve, reject) => {
            client
                .post<SuccessResponse>(
                    '/api/v1/public/confirm-email',
                    {
                        confirmation_token: request.confirmationToken,
                    },
                    {
                        headers: {
                            recaptcha: request.recaptcha,
                        },
                    }
                ).then(resolve)
                .catch((err) => handleError(err, resolve, reject))
        }))
    }
}

export default new Client();

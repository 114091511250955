<template>
    <HighlightComponent />
</template>

<script lang="ts" setup>

import { h, useCssModule } from 'vue';
import type { VNode } from 'vue'

const selectedClass = useCssModule().selected

const props = defineProps({
    value: {
        type: String,
        required: true,
    },

    search: {
        type: String,
        required: true,
    },
});

const HighlightComponent = (): VNode => {
    const value = props.value
        .replace(/(^\w+:|^)\/\//, '')
        .replace(/^\/|\/$/g, '')
    
    if (props.search === '') {
        return h('span', value)
    }

    const pattern = props.search.replace(/([.+*?[^\]$(){}=!<>|:])/g, '\\$1')
    const match = value.split(new RegExp(`(${pattern})`, 'gi'))

    if (match.length === 1) {
        return h('span', value)
    }

    const nodes: VNode[] = []

    match.forEach((value, key) => {
        if (key % 2) {
            nodes.push(h('span', { class: [selectedClass]}, match[key]))
        } else {
            nodes.push(h('span', match[key]))
        }
    })

    return h('span', nodes)
}

</script>

<style lang="less" module>
@import (reference) "../../assets/styles/variables.less";

.selected {
    background: @yellow-200;
}
</style>

<template>
    <LayoutBase @keyup.enter="onEmailChange">
        <template #title>{{ t("emailChange") }}</template>
        <template #description />

        <AuthInput
            id="new-email"
            v-model="email"
            :label="t('newEmail')"
        />

        <AuthInput
            id="password"
            v-model="password"
            :label="t('password')"
            type="password"
        />

        <div class="row row_top-m">
            <UiButton
                :disabled="!isChange || isSending"
                size="md"
                class="full-width"
                @click="onEmailChange"
            >
                {{ t("doChange") }}
            </UiButton>
        </div>

        <div v-if="isSend" class="row row_top-m">
            <p class="center-text">{{ t('sentInfo', { email: email }) }}</p>

            <UiButton
                size="md"
                :disabled="isSending"
                variant="tertiary"
                class="full-width"
                @click="onEmailChange"
            >
                {{ t('sendAgain') }}
            </UiButton>
        </div>

        <div v-else-if="errorMessage" class="row row_top-m">
            <UiAlert :text="errorTranslate" :closable="false" type="danger" />
        </div>
    </LayoutBase>
</template>

<script lang="ts" setup>
import LayoutBase from '@/components/layouts/LayoutBase.vue';
import AuthInput from '@/components/common/AuthInput.vue';

import { computed, watch, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import client from '@/api/private/client';
import { useSending } from '@/composables/sending';

const { t } = useI18n();

const email = ref<string>('');
const password = ref<string>('');
const errorMessage = ref<string>('');
const isSend = ref<boolean>(false);
const {isSending, setSending} = useSending();

watch([email, password], () => {
    errorMessage.value = ''
})

const allowedErrors = new Map<string, string>([
    ['Email is a required field', t('error.emptyEmail')],
    ['Password is a required field', t('error.emptyPassword')],
    ['Email must be a valid email address', t('error.invalidEmail')],
    ['disable for service account', t('error.disableServiceAccount')],
    ['password is invalid', t('error.invalidPassword')],
    ['same email can not be set', t('error.sameEmail')],
    ['email change has been already requested', t('error.alreadyRequested')],
    ['email is unavailable for registration', t('error.duplicateEmail')],
])

const errorTranslate = computed((): string => {
    return allowedErrors.get(errorMessage.value) ?? t('error.undefinedError')
});

const isChange = computed((): boolean => {
    return email.value !== '' && password.value !== ''
});

const onEmailChange = () => {
    if (!isChange.value) {
        return
    }

    setSending(true)
    client.changeEmail({
        password: password.value,
        email: email.value,
    })
        .then((response) => {
            errorMessage.value = ''

            if (response.error?.messages && response.error.messages.length) {
                errorMessage.value = response.error.messages[0]

                return
            }

            isSend.value = true
        })
        .finally(() => setSending(false))
}

</script>

<style lang="less" scoped>
.full-width {
    width: 100%;
}

.center-text {
    text-align: center;
}
</style>

<i18n locale="ru_RU" lang="json">
{
    "emailChange": "Изменение почты",
    "newEmail": "Новая почта",
    "password": "Пароль",
    "doChange": "Изменить почту",
    "sentInfo": "Мы отправили ссылку для подтверждения на почту {email}. Изменения вступят в силу после подтверждения почты.",
    "sendAgain": "Отправить еще раз",
    "error": {
        "emptyEmail": "Введите новую почту",
        "emptyPassword": "Введите текущий пароль",
        "invalidEmail": "Почта введена не верно",
        "invalidPassword": "Неверный пароль",
        "alreadyRequested": "Вы уже запрашивали изменение почты",
        "duplicateEmail": "Такой email уже зарегистрирован в системе",
        "sameEmail": "Текущий email не должен совпадать с измененным",
        "disableServiceAccount": "Пользователь тех. поддержки не может менять почту",
        "undefinedError": "Неизвестная ошибка"
    }
}
</i18n>

<i18n locale="en_GB" lang="json">
{
    "emailChange": "Changing email",
    "newEmail": "New email",
    "password": "Password",
    "doChange": "Change email",
    "sentInfo": "We sent a confirmation link to the email {email}. Changes will take effect after the email is confirmed.",
    "sendAgain": "Send again",
    "error": {
        "emptyEmail": "Enter new mail",
        "emptyPassword": "Enter the current password",
        "invalidEmail": "Email entered is incorrect",
        "invalidPassword": "Incorrect password",
        "alreadyRequested": "You have already requested to change your email",
        "duplicateEmail": "This email is already registered in the system",
        "sameEmail": "The current email should not coincide with the changed",
        "disableServiceAccount": "User of tech. support can't change email",
        "undefinedError": "Unknown error"
    }
}
</i18n>

<i18n locale="es_ES" lang="json">
{
    "emailChange": "Cambio del email",
    "newEmail": "Nuevo email",
    "password": "Contraseña",
    "doChange": "Cambiar email",
    "sentInfo": "Hemos enviado el enlace de confirmación al correo {email}. Los cambios se aplicarán apenas esté confirmado el correo electrónico",
    "sendAgain": "Enviar una vez más",
    "error": {
        "emptyEmail": "Ingrese nuevo correo",
        "emptyPassword": "Introduzca la contraseña actual",
        "invalidEmail": "El correo electrónico ingresado es incorrecto",
        "invalidPassword": "Contraseña incorrecta",
        "alreadyRequested": "Ya has solicitado cambiar tu correo",
        "duplicateEmail": "Este email ya está registrado en el sistema",
        "sameEmail": "Email actual no debe coincidir con el anterior",
        "disableServiceAccount": "Usuario de tecnología. el soporte no puede cambiar el correo",
        "undefinedError": "Error desconocido"
    }
}
</i18n>

<template>
    <LayoutBase @keyup.enter="onLogin">
        <template #description>
            {{ $t("common.noAccount") }}
            <UiLink :href="registrationUrl">{{ $t("common.register") }}</UiLink>
        </template>

        <AuthInput id="login-email" v-model="email" :label="$t('common.email')" />

        <AuthInput
            id="login-password"
            v-model="password"
            type="password"
            :label="$t('common.password')"
        />

        <div class="row row_top-m" :class="$style['form-row']">
            <UiCheckbox v-model:model="rememberMe" :label="t('rememberMe')" />
            <router-link v-slot="{ navigate }" custom :to="{ name: 'reminder' }">
                <UiLink :class="$style['forget-link']" href="#" @click="navigate">
                    {{ t("forgetPassword") }}
                </UiLink>
            </router-link>
        </div>

        <div class="row row_top-m">
            <UiButton
                size="md"
                :disabled="isSending"
                :class="$style['full-width']"
                @click="onLogin"
            >
                {{ t("signIn") }}
            </UiButton>
        </div>

        <div v-if="successMessage" class="row row_top-m">
            <UiAlert
                :text="successMessage"
                :closable="false"
                type="success"
                class="alert"
            />
        </div>

        <div v-if="errorMessage" class="row row_top-m">
            <UiAlert :text="errorTranslate" :closable="false" type="danger" />
        </div>
    </LayoutBase>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { computed, ref, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import LayoutBase from '@/components/layouts/LayoutBase.vue';
import AuthInput from '@/components/common/AuthInput.vue';
import { useMeStore } from '@/stores/me';
import { usePasswordStore } from '@/stores/password';
import { useReCaptcha } from '@/plugins/reCaptcha';
import { useMetrika } from '@/plugins/metrika';
import { useSending } from '@/composables/sending';

const { t, locale } = useI18n();
const router = useRouter();
const route = useRoute();
const { login } = useMeStore();
const { reCaptcha } = useReCaptcha();
const { makeRequestChangePassword, makeRequestResetPassword } = usePasswordStore();
const metrika = useMetrika();
const {isSending, setSending} = useSending();

const email = ref<string>('');
const password = ref<string>('');
const rememberMe = ref<boolean>(true);

const errorMessage = ref<string>('');

const allowedErrors = new Map<string, string>([
    ['Email is a required field', t('error.emptyEmail')],
    ['Password is a required field', t('error.emptyPassword')],
    ['Email must be a valid email address', t('error.notValidEmail')],
    ['invalid credentials', t('error.authError')],
])

const errorTranslate = computed((): string => {
    return allowedErrors.get(errorMessage.value) ?? t('error.undefinedError')
});

const successMessage = computed((): string => {
    if (makeRequestResetPassword) {
        return t('resetPassword');
    }

    if (makeRequestChangePassword) {
        return t('changePassword');
    }

    return '';
});

watch([email, password], () => {
    errorMessage.value = ''
})

const onLogin = () => {
    setSending(true)
    reCaptcha.execute('login').then(function(token: string) {
        login(email.value, password.value, rememberMe.value, token)
            .then(({ error }) => {
                errorMessage.value = '';

                if (error != null) {
                    errorMessage.value = error.messages[0];

                    return;
                }

                metrika.reachGoal('login');

                router.push({ name: 'main', query: route.query });
            })
            .finally(() => setSending(false));
    });
};

const registrationUrl = computed(() => {
    return import.meta.env.VITE_REGISTRATION_URL + '?lang=' + locale.value.split('_')[0]
})
</script>

<style lang="less" module>
.form-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.forget-link {
  margin-top: -1px;
  white-space: nowrap;
}

.full-width {
  width: 100%;
}
</style>

<i18n locale="ru_RU" lang="json">
{
    "forgetPassword": "Забыли пароль?",
    "signIn": "Войти",
    "rememberMe": "Запомнить меня",
    "changePassword": "Мы отправили письмо с\u00a0инструкцией как сбросить пароль",
    "resetPassword": "Пароль сброшен",
    "error": {
        "emptyEmail": "Введите почту",
        "emptyPassword": "Введите пароль",
        "notValidEmail": "Почта введена не верно",
        "authError": "Неверная почта или пароль",
        "undefinedError": "Неизвестная ошибка"
    }
}
</i18n>

<i18n locale="en_GB" lang="json">
{
    "forgetPassword": "Forget password?",
    "signIn": "Sign in",
    "rememberMe": "Remember me on this computer",
    "changePassword": "We sent an email with\u00a0instructions on how to reset the password",
    "resetPassword": "Password reset",
    "error": {
        "emptyEmail": "Enter your email",
        "emptyPassword": "Enter password",
        "notValidEmail": "Email entered is incorrect",
        "authError": "Incorrect email or password",
        "undefinedError": "Unknown error"
    }
}
</i18n>

<i18n locale="es_ES" lang="json">
{
    "forgetPassword": "¿Has olvidado contraseña?",
    "signIn": "Entrar",
    "rememberMe": "Recordarme en este ordenador",
    "changePassword": "Hemos enviado el correo con\u00a0la instrucción para resetear la contraseña",
    "resetPassword": "Contraseña reseteada",
    "error": {
        "emptyEmail": "Introduce tu correo electrónico",
        "emptyPassword": "Introducir la contraseña",
        "notValidEmail": "El correo electrónico ingresado es incorrecto",
        "authError": "El email o la contraseña es incorrecta",
        "undefinedError": "Error desconocido"
    }
}
</i18n>

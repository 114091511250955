<template>
    <LayoutBase @keyup.enter="onChangePassword">
        <template #title>{{ t("passwordChange") }}</template>
        <template #description />

        <AuthInput
            id="old-password"
            v-model="oldPassword"
            :label="t('currentPassword')"
            type="password"
        />

        <AuthInput
            id="password"
            v-model="newPassword"
            :label="t('newPassword')"
            with-tooltip
            type="password"
        />

        <AuthInput
            id="password-confirmation"
            v-model="confPassword"
            :label="t('newPasswordConfirmation')"
            type="password"
        />

        <div class="row row_top-m">
            <UiButton
                :disabled="!isChange || isSending"
                size="md"
                class="full-width"
                @click="onChangePassword"
            >
                {{ t("save") }}
            </UiButton>
        </div>

        <div v-if="errorMessage" class="row row_top-m">
            <UiAlert :text="errorTranslate" :closable="false" type="danger" />
        </div>
    </LayoutBase>
</template>

<script lang="ts" setup>
import LayoutBase from '@/components/layouts/LayoutBase.vue';
import AuthInput from '@/components/common/AuthInput.vue';
import client from '@/api/private/client';
import { computed, defineEmits, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useSending } from '@/composables/sending';

const { t } = useI18n();
const {isSending, setSending} = useSending();

const emit = defineEmits<(e: 'success') => void>()

const oldPassword = ref<string>('');
const newPassword = ref<string>('');
const confPassword = ref<string>('');

const errorMessage = ref<string>('');

const isChange = computed((): boolean => {
    return oldPassword.value !== '' && newPassword.value !== '' && confPassword.value !== ''
});

const allowedErrors = new Map<string, string>([
    ['OldPassword is a required field', t('error.emptyOldPassword')],
    ['NewPassword is a required field', t('error.emptyNewPassword')],
    ['ConfPassword is a required field', t('error.emptyConfPassword')],
    ['NewPassword is invalid - it must contains 6-64 symbols including latin letters in upper or lower case, digits or special symbols', t('error.invalidNewPassword')],
    ['NewPassword cannot be equal to OldPassword', t('error.oldSameAsNew')],
    ['ConfPassword must be equal to NewPassword', t('error.noMatch')],
    ['old password invalid', t('error.invalidOldPassword')],
    ['disable for service account', t('error.disableServiceAccount')],
]);

const errorTranslate = computed((): string => {
    return allowedErrors.get(errorMessage.value) ?? t('error.undefinedError')
});

watch([oldPassword, newPassword, confPassword], () => {
    errorMessage.value = ''
})

const onChangePassword = () => {
    if (!isChange.value) {
        return
    }

    setSending(true)
    client
        .changePassword({
            oldPassword: oldPassword.value,
            newPassword: newPassword.value,
            confPassword: confPassword.value,
        })
        .then(async ({ error }) => {
            errorMessage.value = ''
            
            if (error?.messages.length) {
                errorMessage.value = error.messages[0]

                return;
            }

            emit('success')
        })
        .finally(() => setSending(false));
};
</script>

<style lang="less" scoped>
.full-width {
  width: 100%;
}
</style>

<i18n locale="ru_RU" lang="json">
{
  "passwordChange": "Изменение пароля",
  "currentPassword": "Текущий пароль",
  "newPassword": "Новый пароль",
  "newPasswordConfirmation": "Подтверждение нового пароля",
  "save": "Сохранить",
  "error": {
      "emptyOldPassword": "Введите текущий пароль",
      "emptyNewPassword": "Введите новый пароль",
      "emptyConfPassword": "Повторите ввод нового пароля",
      "invalidOldPassword": "Введенный пароль неверный",
      "invalidNewPassword": "Неверный формат нового пароля",
      "oldSameAsNew": "Новый пароль не должен совпадать со старым",
      "noMatch": "Пароли не совпадают",
      "disableServiceAccount": "Пользователь тех. поддержки не может менять пароль",
      "undefinedError": "Неизвестная ошибка"
  }
}
</i18n>

<i18n locale="en_GB" lang="json">
{
  "passwordChange": "Change password",
  "currentPassword": "Current password",
  "newPassword": "New password",
  "newPasswordConfirmation": "New password confirmation",
  "save": "Save",
  "error": {
      "emptyOldPassword": "Enter the current password",
      "emptyNewPassword": "Enter a new password",
      "emptyConfPassword": "Re-enter the new password",
      "invalidOldPassword": "The entered password is invalid",
      "invalidNewPassword": "Invalid new password format",
      "oldSameAsNew": "The new password must not match the old one",
      "noMatch": "Password mismatch",
      "disableServiceAccount": "User of tech. support cannot change the password",
      "undefinedError": "Unknown error"
  }
}
</i18n>

<i18n locale="es_ES" lang="json">
{
  "passwordChange": "Cambio de contraseña",
  "currentPassword": "Contraseña actual",
  "newPassword": "Nueva contraseña",
  "newPasswordConfirmation": "Confirmación de nueva contraseña",
  "save": "Guardar",
  "error": {
      "emptyOldPassword": "Introduzca la contraseña actual",
      "emptyNewPassword": "Introduzca una nueva contraseña",
      "emptyConfPassword": "Vuelva a introducir la nueva contraseña",
      "invalidOldPassword": "La contraseña ingresada es incorrecta",
      "invalidNewPassword": "Nuevo formato de contraseña no válido",
      "oldSameAsNew": "Nueva contraseña no debe coincidir con la contraseña antigua",
      "noMatch": "Las contraseñas no coinciden",
      "disableServiceAccount": "Usuario de tecnología. soporte no puede cambiar la contraseña",
      "undefinedError": "Error desconocido"
  }
}
</i18n>

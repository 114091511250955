import { createApp } from 'vue';
import { createPinia } from 'pinia';

import App from './App.vue';
import router from './router';
import i18n from './i18n';
import Omnica from '@/plugins/omnica';
import { ReCaptcha } from '@/plugins/reCaptcha';
import { Gtm } from '@/plugins/gtm';
import { Metrika } from '@/plugins/metrika';
import { Sentry } from '@/plugins/sentry';

import './assets/styles/main.less';

const app = createApp(App);

app.use(createPinia());
app.use(router);
app.use(i18n);
app.use(Omnica);
app.use(ReCaptcha, {
    siteKey: import.meta.env.VITE_RECAPTCHA_SITE_KEY,
});
app.use(Gtm, {
    id: import.meta.env.VITE_GTM_ID,
    vueRouter: router,
})
app.use(Metrika, {
    id: import.meta.env.VITE_METRIKA_ID,
    vueRouter: router,
})
app.use(Sentry, {
    dsn: import.meta.env.VITE_SENTRY_DSN,
    vueRouter: router,
})

app.mount('#app');

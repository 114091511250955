<template>
    <LayoutError :img-src="Image404" :title="t('title')" error-code="404">
        <p>
            {{ t("description.first") }}
            <br />
            {{ t("description.second") }}
        </p>
        <p>
            {{ t("description.third") }}
            <UiLink @click="toMain">{{ t("description.mainPage") }}</UiLink>
        </p>
    </LayoutError>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import LayoutError from '@/components/layouts/LayoutError.vue';
import Image404 from '../assets/img/404-decor.svg';
import { useRouter } from 'vue-router';
import { useError } from '@/composables/error';

const { t } = useI18n();

const { push } = useRouter();
const { resetCode } = useError();

const toMain = () => {
    resetCode()
    push({ name: 'main' })
}

</script>

<i18n locale="ru_RU" lang="json">
{
  "title": "Увы... Такой страницы нет",
  "description": {
    "first": "Страница, которую вы запрашиваете, не существует.",
    "second": "Возможно, вы ошиблись в адресе или страница была удалена.",
    "third": "Попробуйте начать с",
    "mainPage": "главной страницы."
  }
}
</i18n>

<i18n locale="en_GB" lang="json">
{
  "title": "Oops… This page does not exist",
  "description": {
    "first": "The page you are looking for does not exist.",
    "second": "Perhaps you entered the wrong address or the page was deleted.",
    "third": "Try to start with",
    "mainPage": "the main page."
  }
}
</i18n>

<i18n locale="es_ES" lang="json">
{
  "title": "Ups... Esta página no existe",
  "description": {
    "first": "La página que estás solicitando no existe.",
    "second": "Es posible que te hayas equivocado en la dirección o la página haya sido borrada.",
    "third": "Intenta con la",
    "mainPage": "página principal."
  }
}
</i18n>

import { ref } from 'vue';

const isSending = ref<boolean>(false)

export function useSending() {
    const setSending = (value: boolean) => {
        isSending.value = value
    }

    return {
        isSending,
        setSending,
    }
}

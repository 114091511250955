<template>
    <PasswordChange v-if="!isPasswordChange" @success="onSuccess" />
    <PasswordChangeResult v-else />
</template>

<script lang="ts" setup>
import PasswordChange from '@/components/password-change/PasswordChange.vue';
import PasswordChangeResult from '@/components/password-change/PasswordChangeResult.vue';
import { ref } from 'vue';

const isPasswordChange = ref<boolean>(false)

const onSuccess = () => {
    isPasswordChange.value = true
}
</script>

<template>
    <div
        :class="{
            [$style['tooltip']]: true,
            [$style['tooltip_show']]: show,
        }"
    >
        <div :class="$style['tooltip__title']">
            {{ t('yourPassword') }}
            <span
                :class="{
                    [$style['tooltip__status']]: true,
                    [$style['tooltip__status_strong']]: status === STATUS.STRONG,
                    [$style['tooltip__status_medium']]: status === STATUS.MEDIUM,
                    [$style['tooltip__status_fair']]: status === STATUS.FAIR,
                    [$style['tooltip__status_weak']]: status === STATUS.WEAK,
                }"
            >
                {{ {
                    [STATUS.STRONG]: t('strength.strong'),
                    [STATUS.MEDIUM]: t('strength.medium'),
                    [STATUS.FAIR]: t('strength.fair'),
                    [STATUS.WEAK]: t('strength.weak'),
                }[status] }}
            </span>
        </div>

        <div :class="$style['tooltip__list']">
            <div :class="{
                [$style['tooltip__row']]: true,
                [$style['tooltip__row_success']]: longEnough
            }"
            >
                <IconDone :class="$style['tooltip__icon']" />
                <div :class="$style['tooltip__tip']">
                    {{ t('passwordRequires.moreThan6Symbols') }}
                </div>
            </div>
        </div>

        <div :class="$style['tooltip__title']">
            {{ t('forReliability') }}
        </div>

        <div :class="$style['tooltip__list']">
            <div :class="{
                [$style['tooltip__row']]: true,
                [$style['tooltip__row_success']]: hasDigits }"
            >
                <IconDone :class="$style['tooltip__icon']" />
                <div :class="$style['tooltip__tip']">
                    {{ t('passwordRequires.digits') }}
                </div>
            </div>

            <div :class="{
                [$style['tooltip__row']]: true,
                [$style['tooltip__row_success']]: hasLetters }"
            >
                <IconDone :class="$style['tooltip__icon']" />
                <div :class="$style['tooltip__tip']">
                    {{ t('passwordRequires.lowerUpperCase') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import IconDone from '@/components/icons/IconDone.vue';

import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const MIN_PASSWORD_LENGTH = 6;

const props = defineProps({
    password: {
        type: [String, Number],
        default: '',
    },

    show: {
        type: Boolean,
        default: false,
    },
})

const { t } = useI18n();

const longEnough = computed(() => String(props.password).length >= MIN_PASSWORD_LENGTH)
const hasDigits = computed(() => Boolean(String(props.password).match(/[0-9]/g)))
const hasLetters = computed(() => Boolean(String(props.password).match(/(?=.*\p{Ll})(?=.*\p{Lu})/gu)))

enum STATUS {
    FAIR = 'fair',
    MEDIUM = 'medium',
    STRONG = 'strong',
    WEAK = 'weak',
}

const status = computed(() => {
    return !longEnough.value
        ? STATUS.WEAK
        : hasDigits.value && hasLetters.value
            ? STATUS.STRONG
            : hasDigits.value || hasLetters.value
                ? STATUS.MEDIUM
                : STATUS.FAIR
})
</script>

<style lang="less" module>
@import (reference) "../../assets/styles/variables.less";

.tooltip {
  width: 100%;
  max-width: 204px;
  padding: @spacing-s;
  background-color: @black-500;
  border-radius: @border-radius-l;
  .font-tiny();
  color: #fff;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-50%) scale(0.9);
  transition: 0.25s ease;
  z-index: 2;
  position: absolute;
  left: calc(100% + @spacing-xs);
  top: 50%;
  right: auto;

  &_show {
    transform: translateY(-50%) scale(1);
    opacity: 1;
    visibility: visible;
  }

  &__title {
    margin-bottom: @spacing-xs;

    &_danger {
      color: @red-500;
    }
  }

  &__text {
    margin-bottom: 7px;
  }

  &__status {
    font-weight: 600;

    &_strong { color: @green-500; }
    &_medium { color: @yellow-700; }
    &_fair   { color: @yellow-400; }
    &_weak   { color: @red-500; }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0 0 13px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__icon {
    width: @spacing-s;
    height: @spacing-s;
    fill: @grey-900;
    margin-right: 6px;
  }

  &__tip {
    color: @grey-900;
    margin-top: 0;
    margin-bottom: 2px;
  }

  &__row_success &__icon {
    fill: @green-500;
  }

  &__row_success &__tip {
    color: @green-500;
  }

  @media screen and (max-width: @tablet) {
    left: 0;
    right: auto;
    top: auto;
    bottom: calc(100% + @spacing-xs);
    transform: scale(0.9);

    &_show {
      transform: scale(1);
    }
  }
}
</style>

<i18n locale="ru_RU" lang="json">
{
  "yourPassword": "Ваш пароль",
  "forReliability": "Для большей надежности используйте",
  "passwordRequires": {
    "moreThan6Symbols": "более 6 символов",
    "digits": "цифры",
    "lowerUpperCase": "разный регистр букв"
  },
  "strength": {
    "weak": "не подходит",
    "fair": "легкий",
    "medium": "средний",
    "strong": "надежный"
  }
}
</i18n>

<i18n locale="en_GB" lang="json">
{
  "yourPassword": "Password strength",
  "forReliability": "For stronger protection use",
  "passwordRequires": {
    "moreThan6Symbols": "at least 6 characters",
    "digits": "digits",
    "lowerUpperCase": "upper and lower case letters"
  },
  "strength": {
    "weak": "weak",
    "fair": "fair",
    "medium": "good",
    "strong": "strong"
  }
}
</i18n>

<i18n locale="es_ES" lang="json">
{
  "yourPassword": "Tu contraseña",
  "forReliability": "Para mayor seguridad, usa",
  "passwordRequires": {
    "moreThan6Symbols": "más de 6 dígitos",
    "digits": "número",
    "lowerUpperCase": "diferentes mayúsculas y minúsculas"
  },
  "strength": {
    "weak": "no es adecuada",
    "fair": "fácil",
    "medium": "normal",
    "strong": "segura"
  }
}
</i18n>

import type {
    ClientInterface,
    FastLoginRequest,
    LoginRequest,
    LogoutRequest,
    SuccessResponse,
} from '@/api/auth/interface';
import client from '@/api/client';
import type { Response } from '@/api/response';
import { handleError } from '@/api/response';

class Client implements ClientInterface {
    login(request: LoginRequest): Promise<Response<SuccessResponse>> {
        return new Promise<Response<SuccessResponse>>((resolve, reject) => {
            client
                .post<SuccessResponse>(
                    '/api/v1/auth/login',
                    {
                        email: request.email,
                        password: request.password,
                        remember_me: request.rememberMe,
                    },
                    {
                        headers: {
                            recaptcha: request.recaptcha,
                        },
                    }
                )
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (err) {
                    handleError(err, resolve, reject);
                });
        });
    }

    fastLogin(request: FastLoginRequest): Promise<Response<SuccessResponse>> {
        return new Promise<Response<SuccessResponse>>((resolve, reject) => {
            client
                .post<SuccessResponse>(
                    '/api/v1/auth/fast-login',
                    {
                        token: request.token,
                    },
                    {
                        headers: {
                            recaptcha: request.recaptcha,
                        },
                    }
                )
                .then(function (response) {
                    resolve({
                        data: response.data,
                    });
                })
                .catch(function (err) {
                    handleError(err, resolve, reject);
                });
        });
    }

    logout(request: LogoutRequest): Promise<Response<SuccessResponse>> {
        return new Promise<Response<SuccessResponse>>((resolve, reject) => {
            client
                .post<SuccessResponse>('/api/v1/auth/logout', {},{
                    headers: {
                        recaptcha: request.recaptcha,
                    },
                })
                .then(function (response) {
                    resolve({
                        data: response.data,
                    });
                })
                .catch(function (err) {
                    handleError(err, resolve, reject);
                });
        });
    }
}

export default new Client();

<template>
    <div :class="$style['login']">
        <main id="main"
              :class="{
                  [$style['login__content']]: true,
                  [$style['login__content_top']]: isTopPosition,
                  [$style['login__content_scroll']]: isScroll,
              }"
              :style="styles"
              @scroll="onScroll"
        >
            <div :class="$style['top']">
                <UiButton v-if="showBackToTheSystem"
                          type="button"
                          variant="tertiary"
                          @click="jumpToTheSystem"
                >
                    <UiIcon name="arrowBackward" />
                    {{ t('backToTheSystem') }}
                </UiButton>
                <UiButton
                    v-else-if="isAuthorized"
                    type="button"
                    variant="tertiary"
                    @click="toLogout"
                >
                    <UiIcon name="arrowBackward" />
                    {{ t('logout') }}
                </UiButton>
            </div>
            <div
                ref="formBox"
                :class="{
                    [$style['login__form-wrap']]: true,
                    [$style['login__form-wrap_top']]: isTopPosition,
                }"
            >
                <div v-if="$slots.header" :class="$style['login__header']">
                    <slot name="header" />
                </div>

                <div :class="$style['login__logo']">
                    <img
                        :src="logo"
                        :alt="siteName"
                    />
                </div>

                <h1 :class="$style['login__title']">
                    <slot name="title">{{ t("title", {siteName: siteName}) }}</slot>
                </h1>
                <div :class="$style['login__description']">
                    <slot name="description" />
                </div>
                <div :class="$style['login__form']">
                    <slot />
                </div>
            </div>
        </main>
    </div>
</template>

<script lang="ts" setup>
import { computed, defineEmits, onBeforeUnmount, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import LoginImageRu from '../../assets/img/login__decor_ru.svg';
import LoginImageEn from '../../assets/img/login__decor_en.svg';
import LoginImageEs from '../../assets/img/login__decor_es.svg';
import { useTheme } from '@/composables/theme';
import { LOCALES } from '@/i18n';
import { useRoute, useRouter } from 'vue-router';
import { useMeStore } from '@/stores/me';

const { t, locale } = useI18n();
const { logo, siteName } = useTheme();
const route = useRoute();
const router = useRouter();

const { isAuthorized } = useMeStore();

const IMG_WIDTH = 1001;
const IMG_HEIGHT = 1223;
const PADDING = 40;

const screenWidth = ref(0);
const screenHeight = ref(0);
const formBox = ref<HTMLInputElement | null>(null);

let main : Element | null = null;

defineProps({
    isTopPosition: {
        type: Boolean,
        default: false,
    },
    isScroll: {
        type: Boolean,
        default: false,
    },
})

interface ScrollEvent {
  scrollTop: number;
  clientHeight: number;
  scrollHeight: number;
}

const emit = defineEmits<(e: 'scroll', data: ScrollEvent) => void>()

const backgroundImageUrl = computed(() => {
    switch (locale.value) {
        case LOCALES.EN:
            return LoginImageEn;
        case LOCALES.ES:
            return LoginImageEs;
        default:
            return LoginImageRu;
    }
});

const styles = computed((): string => {
    if (!formBox.value || !screenWidth.value || !screenHeight.value) {
        return '';
    }

    const imgWidth: number = (screenHeight.value * IMG_WIDTH) / IMG_HEIGHT;
    const contentWidth: number = (formBox.value.offsetWidth || 0) + PADDING * 2;
    let biasImage = 0;

    if (screenWidth.value - imgWidth < contentWidth) {
        biasImage = Math.abs(screenWidth.value - imgWidth - contentWidth);
    }

    return `
    background-image: url(${backgroundImageUrl.value});
    background-position: calc(100% + ${biasImage}px) 0;
    padding-right: ${imgWidth - biasImage}px;
  `;
});

const updateScreenSize = (): void => {
    screenWidth.value = window.innerWidth;
    screenHeight.value = window.innerHeight;
};

const toLogout = () => router.push({ name: 'logout', query: route.query })

const onScroll = (): void => {
    if (main == null) {
        return
    }

    emit('scroll', {
        scrollTop: main.scrollTop,
        clientHeight: main.clientHeight,
        scrollHeight: main.scrollHeight,
    })
};

onMounted(() => {
    updateScreenSize();
    window.addEventListener('resize', updateScreenSize);
    main = document.querySelector('#main');
});

onBeforeUnmount(() => {
    window.removeEventListener('resize', updateScreenSize);
});

const showBackToTheSystem = !!route.query.ref

const jumpToTheSystem = () => {
    if (!showBackToTheSystem) {
        return
    }

    location.href = String(route.query.ref)
}
</script>

<style lang="less" module>
@import (reference) "../../assets/styles/variables.less";

@imgWidth: calc(100vh * (1001 / 1223));

.login {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  height: 100%;
  overflow-y: auto;
  background-color: #fff;
  font-family: @font-family-default;
  line-height: 1.5;
  color: @black-500;

  &__content {
    height: 100%;
    width: 100%;
    min-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    background-color: #fff;
    flex: 1;
    padding: @spacing-xl @imgWidth @spacing-xl @spacing-xl;
    position: relative;
    background-repeat: no-repeat;
    background-position: 100% 0;
    background-size: contain;

    &_top {
        align-items: flex-start;
    }

    &_scroll {
        overflow-y: scroll;
    }
  }

  &__inner-wrap {
    height: 100%;
    position: relative;
  }

  &__logo {
    width: 56px;
    height: 56px;
    margin: 0 auto;
  }

  &__title {
    font: 800 40px/44px @font-family-Gilroy;
    text-align: center;
    margin-top: @spacing-m;
    margin-bottom: 0;
  }

  &__description {
    text-align: center;
    margin-top: 6px;
  }

  &__form {
    margin: @spacing-l auto 0;
    max-width: 352px;
  }

  &__form-wrap {
    width: 400px;
    margin: auto;

    &_top {
        margin-top: 0;
    }

    @media screen and (max-width: @phone) {
        width: 100%;
        max-width: 400px;
    }
  }

  &__header {
    margin-bottom: 60px;
  }
}

.top {
    display: flex;
    align-items: flex-start;
    width: 100%
}

@media screen and (max-width: @tablet) {
  .login {
    display: block;

    &__content {
      padding: @spacing-s !important;
      margin: auto;
      background: none !important;
    }

    &__form-wrap {
      margin: auto;
    }
  }
}
</style>

<i18n locale="ru_RU" lang="json">
{
  "title": "Вход в {siteName}",
  "backToTheSystem": "Вернуться в систему",
  "logout": "Выйти"
}
</i18n>

<i18n locale="en_GB" lang="json">
{
  "title": "Log in {siteName}",
  "backToTheSystem": "Back to system",
  "logout": "Sign out"
}
</i18n>

<i18n locale="es_ES" lang="json">
{
  "title": "Entrar a {siteName}",
  "backToTheSystem": "Volver al sistema",
  "logout": "Cerrar sesión"
}
</i18n>

import axios, { AxiosError } from 'axios';
import type { ErrorResponse } from '@/api/response';
import { useMeStore } from '@/stores/me';
import { useError } from '@/composables/error';

const client = axios.create({
    baseURL: import.meta.env.VITE_BACKEND_HOST,
    timeout: 4000,
    headers: {
        'Content-Type': 'application/json',
    },
});

export default client;

client.interceptors.response.use(function (response) {
    return response;
}, function (error: AxiosError<ErrorResponse>) {
    return new Promise(function() {
        const { setCode } = useError();
        const { deAuthorized } = useMeStore()

        if (error.response) {
            setCode(error.response.status);
            
            if (error.response.status === 401) {
                deAuthorized()
            }
        }

        throw error;
    });
});

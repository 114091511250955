import type { Plugin } from 'vue';

import UiAlert from '@omnica/alert-vue3';
import UiButton from '@omnica/button-vue3';
import UiCheckbox from '@omnica/checkbox-vue3';
import UiError from '@omnica/error-vue3';
import UiIcon from '@omnica/icon-vue3';
import UiInput from '@omnica/input-vue3';
import UiLink from '@omnica/link-vue3';
import OmnicaPopperPlugin from '@omnica/popper-vue3';
import OmnicaTooltipPlugin from '@omnica/tooltip-vue3';

export default {
    install(app) {
        app.component('UiAlert', UiAlert);
        app.component('UiButton', UiButton);
        app.component('UiCheckbox', UiCheckbox);
        app.component('UiError', UiError);
        app.component('UiIcon', UiIcon);
        app.component('UiInput', UiInput);
        app.component('UiLink', UiLink);
        app.use(OmnicaPopperPlugin);
        app.use(OmnicaTooltipPlugin);
    },
} as Plugin;

import { ref } from 'vue';
import { defineStore } from 'pinia';
import client from '@/api/private/client';
import { Account } from '@/model/account';
import type { AccountsRequest } from '@/api/private/interface';

export const useAccountsStore = defineStore('accounts', () => {
    const limit = 20;

    const totalCount = ref<number>(0)
    const hasNextPage = ref<boolean>(true)
    const cursor = ref<string | null>(null)
    const accounts = ref<Account[]>([]);

    function clear() {
        totalCount.value = 0
        hasNextPage.value = true
        cursor.value = null
        accounts.value = []
    }

    function fetchAccounts(search: string) {
        if (!hasNextPage.value) {
            return
        }

        const request: AccountsRequest = {
            first: limit,
        }

        if (cursor.value != undefined) {
            request.after = cursor.value
        }

        if (search) {
            request.domain = search
        }

        return client
            .accounts(request)
            .then(({ data, error }) => {
                if (error != null || data == null) {
                    return
                }

                const response = data.data;

                if (totalCount.value === 0) {
                    totalCount.value = response.total_count
                }

                hasNextPage.value = response.has_next_page
                cursor.value = response.end_cursor

                response.accounts.forEach((item) => {
                    if (!accounts.value.filter((account) => account.uid === item.uid).length) {
                        accounts.value.push(new Account(item.uid, item.domain, item.active));
                    }
                });
            })
            .catch((error) => {
                throw error;
            });
    }

    return {
        totalCount,
        hasNextPage,
        cursor,
        accounts,
        clear,
        fetchAccounts,
    };
});

<template>
    <LayoutBase
        :is-top-position="hasMoreAccounts"
        :is-scroll="hasMoreAccounts"
        @scroll="onScroll"
    >
        <template v-if="errorCode" #header>
            <div class="row row_top-m">
                <UiAlert
                    :text="errorCode"
                    :closable="false"
                    type="danger"
                />
            </div>
        </template>

        <template #title>{{ title }}</template>

        <template #description>
            {{ description }}
        </template>

        <template v-if="!isServiceAccount">
            <div>
                <div v-if="hasMoreAccounts" class="row row_bottom-l">
                    <UiInput v-model:value="search" leading-icon="search" />
                </div>

                <div :class="$style['system-list']">
                    <div
                        v-for="account in accountsStore.accounts"
                        :key="account.uid"
                        :class="$style['system-list-btn']"
                    >
                        <span
                            :class="{
                                [$style['system-list-btn__link']]: true,
                                [$style['system-list-btn__link_disabled']]: !account.active,
                            }"
                            @click="getCode(account.uid, query['target-path']?.toString())"
                        >
                            <HighlightText :search="search" :value="account.domain" />
                        </span>

                        <UiTooltip
                            v-if="!account.active"
                            placement="right"
                            :class="$style['system-list-btn__tooltip-btn']"
                        >
                            <template #trigger>
                                <div :class="$style['system-list-btn__tooltip-btn']" />
                            </template>
                            <p>{{ t("accountDisable.top") }}</p>
                            <p>{{ t("accountDisable.bottom") }}</p>
                        </UiTooltip>
                    </div>
                </div>

                <div v-if="isEmpty" :class="$style['empty']">
                    {{ t("empty") }}
                </div>

                <div v-if="isLoading" class="row row_top-m">
                    <UiIcon name="loading" :class="$style['loader-icon']" />
                </div>
            </div>
        </template>
    </LayoutBase>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { ref, onBeforeMount, computed, watch } from 'vue';
import LayoutBase from '@/components/layouts/LayoutBase.vue';
import HighlightText from '@/components/common/HighlightText.vue';
import { useAccountsStore } from '@/stores/accounts';
import privateClient from '@/api/private/client';
import { useMeStore } from '@/stores/me';
import { debounce } from 'lodash';
import { useRoute } from 'vue-router';
import { useGtm } from '@/plugins/gtm';
import { useMetrika } from '@/plugins/metrika';

const { t } = useI18n();
const { query } = useRoute();
const accountsStore = useAccountsStore();
const gtm = useGtm();
const metrika = useMetrika();

const { user, isServiceAccount } = useMeStore();

const search = ref('');
const isLoading = ref(false);
const errorCode = ref('');

const title = computed(() => {
    if (isServiceAccount) {
        return t('systemAccount')
    }

    return t('title')
});

const description = computed((): string => {
    if (isServiceAccount) {
        return t('systemAccountDescription')
    }

    return t('description', { email: user?.email, count: accountsStore.totalCount })
});

const hasMoreAccounts = computed(() => accountsStore.totalCount > 5);

const isEmpty = computed((): boolean => {
    return !isLoading.value &&
        search.value !== '' &&
        accountsStore.totalCount > 0 &&
        accountsStore.accounts.length === 0
});

watch(search, async () => {
    await accountsStore.clear()
    loadAccountsDebounce()
})

onBeforeMount(async () => {
    await accountsStore.clear()
    await autoCode()
    await loadAccounts()
});

const onScroll = ({scrollTop, clientHeight, scrollHeight}: {scrollTop: number, clientHeight: number, scrollHeight: number}) => {
    if (scrollTop + clientHeight >= scrollHeight - 100) {
        loadAccountsDebounce()
    }
};

const loadAccounts = async () => {
    if (isServiceAccount) {
        return
    }

    isLoading.value = true
    await accountsStore.fetchAccounts(search.value);
    isLoading.value = false
}

const loadAccountsDebounce = debounce(loadAccounts, 300)

const autoCode = async () => {
    const uid = query['target-crm']?.toString()

    if (!uid?.length) {
        return;
    }

    await getCode(uid, query['target-path']?.toString())
}

const getCode = (uid: string, path: string | undefined = undefined) => {
    errorCode.value = ''

    privateClient
        .code(uid)
        .then(({ data, error }) => {
            if (error != null) {
                if (error.code === 404) {
                    errorCode.value = t('noTarget');
                } else if (error.code === 403) {
                    errorCode.value = t('targetInactive');
                }
            }

            return data
        })
        .then((data) => {
            gtm.trackEvent('leadFormLogin')
            metrika.reachGoal('enter')

            return data
        })
        .then((data) => {
            if (data === null) {
                return
            }

            let targetUrl = data.data.domain

            if (path) {
                targetUrl += decodeURIComponent(path)
            }

            const url = new URL(targetUrl)

            url.searchParams.set('sso_code', data.data.code)
            if (data.data.remember_me) {
                url.searchParams.set('sso_remember_me', '1')
            }

            location.href = url.toString();
        })
};
</script>

<style lang="less" module>
@import (reference) "../assets/styles/variables.less";

.system-list-btn {
  position: relative;

  & + & {
    margin-top: @spacing-s;
  }

  &__link {
    cursor: pointer;
    display: block;
    color: @black-500;
    padding: @spacing-s;
    border: 1px solid @grey-500;
    border-radius: @border-radius-l;
    transition: 0.2s linear;
    text-decoration: none;
    .font-body-accent;

    &_disabled {
      border-color: @grey-500;
      background: @grey-200;
      color: @grey-900;
      cursor: default;
    }

    &:not(.system-list-btn_disabled):hover {
      background: @blue-100;
      border-color: @blue-500;
    }
  }

  &__tooltip-btn {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: default;

    div {
        width: 100%;
        height: 100%;
    }
  }

  &__tooltip-wrapper {
    width: 100%;
    height: 100%;
  }
}

.loader-icon {
  width: @spacing-m;
  height: @spacing-m;
  fill: @blue-500;
  display: block;
  margin: 0 auto;
}

.empty {
    text-align: center;
}
</style>

<i18n locale="ru_RU" lang="json">
{
  "title": "Выберите систему",
  "description": "Почта {email} не зарегистрирована ни в одной системе | Почта {email} зарегистрирована в {count} системе | Почта {email} зарегистрирована в {count} системах. Выберите, в какую хотите зайти",
  "accountDisable": {
      "top": "Ваш профиль не\u00a0активен в\u00a0этой системе.",
      "bottom": "Для входа свяжитесь с\u00a0администратором системы."
  },
  "systemAccount": "Техническая поддержка",
  "systemAccountDescription": "Вы вошли под аккаунтом технической поддержки. Чтобы перейти в целевую систему, откройте прямую ссылку.",
  "noTarget": "Вы не имеете доступа к запрошенной системе.",
  "targetInactive": "Ваш профиль в запрошенной системе неактивен.",
  "empty": "Нет систем удовлетворяющих вашему запросу."
}
</i18n>

<i18n locale="en_GB" lang="json">
{
    "title": "Choose a system",
    "description": "Mail {email} is not registered in any system | Mail {email} is registered in the {count} system | Mail {email} is registered in {count} systems, select which one you want to log in",
    "accountDisable": {
        "top": "Your profile is not active on this system.",
        "bottom": "To log in, contact the system administrator."
    },
    "systemAccount": "Support account",
    "systemAccountDescription": "You are logged in with a technical support account. To go to the target system, open the direct link.",
    "noTarget": "You do not have access to the requested system.",
    "targetInactive": "Your profile in the requested system is inactive.",
    "empty": "There are no systems that meet your request."
}
</i18n>

<i18n locale="es_ES" lang="json">
{
    "title": "Selecciona el sistema",
    "description": "El email {email} no está registrado en ningún sistema | El email {email} está registrado en {count} sistema | El email {email} está registrado en {count} sistemas, seleccione a cual desea ingresar",
    "accountDisable": {
        "top": "Tu perfil no está activo en este sistema.",
        "bottom": "Para entrar ponte en contacto con el administrador del sistema."
    },
    "systemAccount": "Cuenta de soporte",
    "systemAccountDescription": "Ha iniciado sesión con una cuenta de soporte técnico. Para ir al sistema de destino, abra el enlace directo.",
    "noTarget": "No tiene acceso al sistema solicitado.",
    "targetInactive": "Su perfil en el sistema solicitado está inactivo.",
    "empty": "No hay sistemas que cumplen tu solicitud."
}
</i18n>

<template>
    <template v-if="has500">
        <Error500View />
    </template>
    <template v-else-if="has404">
        <Error404View />
    </template>
    <template v-else>
        <router-view />
    </template>
</template>

<script setup lang="ts">
import Error500View from '@/views/Error500View.vue';
import { computed } from 'vue';
import { useFavicon, useTitle } from '@vueuse/core';
import { useTheme } from '@/composables/theme';
import { useI18n } from 'vue-i18n';
import client from '@/api/client';
import { useError } from '@/composables/error';
import Error404View from '@/views/Error404View.vue';

const { favicon, siteName } = useTheme()
const { has500, has404 } = useError()

const { t } = useI18n();
const { locale } = useI18n<string>({ useScope: 'global' });

const title = computed(() => {
    return `${t('common.title')} ${siteName.value}`
})

useTitle(title)
useFavicon(favicon)

client.interceptors.request.use(function (config) {
    config.headers.set('System-Language', locale.value.replace('_', '-'))

    return config;
}, function (error) {
    return error;
});

</script>

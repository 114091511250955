<template>
    <LayoutBase v-if="isSend">
        <template #title>{{ title }}</template>

        <div v-if="!hasError" class="row row_bottom-m">
            <UiButton size="lg" class="full-width" @click="toMain">
                {{ t("toMain") }}
            </UiButton>
        </div>
        <div v-else class="row row_top-m">
            <UiAlert :text="errorTranslate" :closable="false" type="danger" />
        </div>
    </LayoutBase>
</template>

<script lang="ts" setup>
import LayoutBase from '@/components/layouts/LayoutBase.vue';
import { useI18n } from 'vue-i18n';
import { onBeforeMount, computed, ref } from 'vue';
import client from '@/api/public/client';
import { useRoute, useRouter } from 'vue-router';
import {useReCaptcha} from '@/plugins/reCaptcha';

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const { reCaptcha } = useReCaptcha();

const errorMessage = ref<string>('');
const isSend = ref<boolean>(false);

const title = computed(() => {
    return hasError.value ? t('title.error'): t('title.success')
})

const hasError = computed((): boolean => {
    return errorMessage.value !== ''
});

const allowedErrors = new Map<string, string>([
    ['user not found', t('error.userNotFound')],
    ['invalid token', t('error.invalidToken')],
    ['expired token', t('error.expiredToken')],
    ['disable for service account', t('error.disableServiceAccount')],
    ['confirmation was not requested', t('error.notRequested')],
])

const errorTranslate = computed((): string => {
    return allowedErrors.get(errorMessage.value) ?? t('error.undefinedError')
})

onBeforeMount(async () => {
    const emailToken = route.query.token

    if (!emailToken) {
        await router.push({ name: 'main' })

        return
    }

    await reCaptcha.execute('email_confirm')
        .then((token) => {
            client.confirmEmail({
                'confirmationToken': emailToken as string,
                'recaptcha': token,
            }).then((response) => {
                isSend.value = true

                if (!response.error) {
                    return
                }

                errorMessage.value = response.error.messages[0]
            })
        })
})

const toMain = () => router.push({ name: 'main' })

</script>

<style lang="less" scoped>
.full-width {
    width: 100%;
}
</style>

<i18n locale="ru_RU" lang="json">
{
    "toMain": "На главную",
    "title": {
        "success": "Ваш email изменён!",
        "error": "Возникла ошибка при подтверждении email"
    },
    "error": {
        "userNotFound": "Невозможно подтвердить новую почту",
        "expiredToken": "Истек срок действия ссылки подтверждения почты",
        "invalidToken": "Неверный токен подтверждения почты",
        "disableServiceAccount": "Пользователь тех. поддержки не может подтверждать почту",
        "notRequested": "Вы не запрашивали подтверждение почты",
        "undefinedError": "Неизвестная ошибка"
    }
}
</i18n>

<i18n locale="en_GB" lang="json">
{
    "toMain": "To main",
    "title": {
        "success": "Your email has been changed",
        "error": "An error occurred during email confirmation"
    },
    "error": {
        "userNotFound": "Unable to confirm the user",
        "expiredToken": "Email confirmation link expired",
        "invalidToken": "Invalid email verification token",
        "disableServiceAccount": "User of tech. support can't verify email",
        "notRequested": "You didn't request email confirmation",
        "undefinedError": "Unknown error"
    }
}
</i18n>

<i18n locale="es_ES" lang="json">
{
    "toMain": "A la página principal",
    "title": {
        "success": "Tu email ha sido cambiado",
        "error": "Error de confirmación del email"
    },
    "error": {
        "userNotFound": "No se puede confirmar el usuario",
        "expiredToken": "El enlace de confirmación de email se expiró",
        "invalidToken": "Token de verificación de correo no válido",
        "disableServiceAccount": "Usuario de tecnología. el soporte no puede verificar el correo electrónico",
        "notRequested": "No solicitaste la confirmación por correo electrónico",
        "undefinedError": "Error desconocido"
    }
}
</i18n>

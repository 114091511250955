<template>
    <div :class="$style['system-page']">
        <div :class="$style['system-page__container']">
            <div :class="$style['system-page__error-name']">
                {{ t("error", { code: errorCode }) }}
            </div>
            <h1 :class="$style['system-page__title']">{{ title }}</h1>
            <div :class="$style['system-page__info']">
                <slot />
            </div>
        </div>
        <img :src="imgSrc" alt="picture" :class="$style['system-page__decor']" />
    </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';

defineProps({
    imgSrc: {
        type: String,
        required: true,
        default: '',
    },

    errorCode: {
        type: String,
        default: '404',
    },

    title: {
        type: String,
        default: '',
    },
});

const { t } = useI18n();
</script>

<style lang="less" module>
@import (reference) "../../assets/styles/variables.less";

.system-page {
  display: flex;
  justify-content: space-between;
  height: 100%;
  position: relative;
  padding: 180px 157px 96px;
  background-color: #fff;
  font-family: @font-family-default;
  line-height: 1.5;
  color: @black-500;

  &__container {
    position: relative;
    max-width: 590px;
  }

  &__error-name {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 24px;
  }

  &__title {
    font-size: 40px;
    line-height: 44px;
    font-family: @font-family-Gilroy;
    font-weight: 800;
    margin-bottom: 32px;
  }

  &__info {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
  }

  &__decor {
    align-self: flex-end;
  }

  p {
    margin-bottom: 16px;
  }
  @media screen and (max-width: @tablet) {
    padding: 0;
    flex-direction: column;
    justify-content: center;

    &__container {
      padding: 16px;
      margin: 0 auto;
    }

    &__decor {
      margin: 40px auto 0;
      max-width: 400px;
      width: 100%;
    }
  }

  @media screen and (max-width: @phone) {
    justify-content: flex-start;
  }
}
</style>

<i18n locale="ru_RU" lang="json">
{
  "error": "Ошибка {code}"
}
</i18n>

<i18n locale="en_GB" lang="json">
{
  "error": "Error {code}"
}
</i18n>

<i18n locale="es_ES" lang="json">
{
  "error": "Error {code}"
}
</i18n>

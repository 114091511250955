import { defineStore } from 'pinia';
import authClient from '@/api/auth/client';
import privateClient from '@/api/private/client';
import { computed, ref } from 'vue';
import { User } from '@/model/user';

export const useMeStore = defineStore('me', () => {
    const isAuthorized = ref<boolean | undefined>(undefined);
    const user = ref<User | null>(null);

    const isServiceAccount = computed(() : boolean => {
        return !!user.value?.isServiceAccount
    })

    const me = async () => {
        return privateClient
            .me()
            .then(({data}) => {
                if (data != null) {
                    isAuthorized.value = true;

                    user.value = new User(
                        data.data.user.email,
                        data.data.user.is_service_account
                    );
                } else {
                    isAuthorized.value = false
                }

                return data;
            });
    }

    const login = (
        email: string,
        password: string,
        rememberMe: boolean,
        token: string
    ) => {
        return authClient
            .login({
                email: email,
                password: password,
                rememberMe: rememberMe,
                recaptcha: token,
            })
            .then((response) => {
                if (response.data != null) {
                    isAuthorized.value = true
                }

                return response;
            });
    };

    const fastLogin = (fastLoginToken: string, token: string) => {
        return authClient.fastLogin({
            token: fastLoginToken,
            recaptcha: token,
        }).then((response) => {
            if (response.data != null) {
                isAuthorized.value = true
            }

            return response;
        });
    };

    const logout = (token: string) => {
        return authClient.logout({
            recaptcha: token,
        }).then((response) => {
            if (response.data != null) {
                deAuthorized();
            }

            return response;
        });
    }

    const deAuthorized = () => {
        user.value = null;
        isAuthorized.value = false;
    };

    return { isAuthorized, user, isServiceAccount, me, login, logout, fastLogin, deAuthorized };
});

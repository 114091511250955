import { computed, ref } from 'vue';

enum Theme {
    RETAILCRM = 'retailcrm',
    SIMLA = 'simla'
}

enum Region {
    RU = 'ru-central-1',
    EU = 'eu-central-1'
}

const theme = ref<Theme>(Theme[import.meta.env.VITE_THEME])
const region = ref<Region>(Region[import.meta.env.VITE_S3_REGION])

export function useTheme() {
    const logo = computed(() => {
        return `https://s3-s1.retailcrm.tech/${region.value.toString()}/retailcrm-static/branding/${theme.value.toString()}/logo/logo_icon.svg`
    })

    const favicon = computed(() => {
        return `https://s3-s1.retailcrm.tech/${region.value.toString()}/retailcrm-static/branding/${theme.value.toString()}/favicon/favicon.ico`
    })

    const siteName = computed(() => {
        return theme.value === Theme.RETAILCRM ? 'RetailCRM' : 'Simla.com'
    })

    const setTheme = (newTheme: 'RETAILCRM'|'SIMLA') => {
        theme.value = Theme[newTheme]
    }

    return {
        theme,
        logo,
        favicon,
        siteName,
        setTheme,
    }
}

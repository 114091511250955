<template>
    <LayoutBase @keyup.enter="onRequestPassword">
        <template #title>{{ t("title", { siteName: siteName }) }}</template>
        <template #description>
            {{ $t("common.noAccount") }}
            <UiLink :href="registrationUrl">{{ $t("common.register") }}</UiLink>
        </template>

        <AuthInput id="login-email" v-model="email" :label="$t('common.email')" />

        <div class="row row_top-m">
            <UiButton
                :class="$style['full-width']"
                :disabled="!email.length || isSending"
                size="md"
                @click="onRequestPassword"
            >
                {{ t("receiveEmail") }}
            </UiButton>
        </div>

        <div v-if="errorMessage" class="row row_top-m">
            <UiAlert :text="errorTranslate" :closable="false" type="danger" />
        </div>
      
        <div class="row row_top-m" :class="$style['link-row']">
            <router-link v-slot="{ navigate }" custom to="/">
                <UiLink href="" @click="navigate">
                    {{ t("backward") }}
                </UiLink>
            </router-link>
        </div>
    </LayoutBase>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n';
import { computed, ref, watch } from 'vue';
import LayoutBase from '@/components/layouts/LayoutBase.vue';
import AuthInput from '@/components/common/AuthInput.vue';
import { usePasswordStore } from '@/stores/password';
import { useRouter } from 'vue-router';
import { useReCaptcha } from '@/plugins/reCaptcha';
import { useTheme } from '@/composables/theme';
import { useSending } from '@/composables/sending';

const router = useRouter();
const { t, locale } = useI18n();
const { reCaptcha } = useReCaptcha();
const { siteName } = useTheme();
const {isSending, setSending} = useSending();

const { fetchRequestPassword } = usePasswordStore();

const email = ref('');
const errorMessage = ref<string>('');

const allowedErrors = new Map<string, string>([
    ['Email is a required field', t('error.emptyEmail')],
    ['Email must be a valid email address', t('error.invalidEmail')],
    ['user not found', t('error.userNotFound')],
    ['disable for service account', t('error.disableServiceAccount')],
    ['password already requested', t('error.alreadyRequested')],
])

const errorTranslate = computed((): string => {
    return allowedErrors.get(errorMessage.value) ?? t('error.undefinedError')
});

const registrationUrl = computed(() => {
    return import.meta.env.VITE_REGISTRATION_URL + '?lang=' + locale.value.split('_')[0]
})

watch(email, async () => {
    errorMessage.value = '';
});

const onRequestPassword = () => {
    setSending(true)
    reCaptcha.execute('request_password').then(function (token: string) {
        fetchRequestPassword(email.value, token)
            .then(({ error }) => {
                errorMessage.value = ''
                if (error != null) {
                    errorMessage.value = error.messages[0];

                    return;
                }

                router.push({ name: 'login' });
            })
            .finally(() => setSending(false));
    })
};
</script>

<style lang="less" module>
.link-row {
  text-align: center;
}

.full-width {
  width: 100%;
}
</style>

<i18n locale="ru_RU" lang="json">
{
    "title": "Восстановление доступа в {siteName}",
    "receiveEmail": "Получить письмо с инструкцией",
    "backward": "Назад",
    "error": {
        "emptyEmail": "Введите почту",
        "invalidEmail": "Почта введена не верно",
        "userNotFound": "Данная почта не зарегистрирована в системе",
        "disableServiceAccount": "Пользователь тех. поддержки не может менять пароль",
        "alreadyRequested": "Вы уже запрашивали изменение пароля",
        "undefinedError": "Неизвестная ошибка"
    }
}
</i18n>

<i18n locale="en_GB" lang="json">
{
    "title": "Restoring access to {siteName}",
    "receiveEmail": "Receive an email with instructions",
    "backward": "Back",
    "error": {
        "emptyEmail": "Enter your email",
        "invalidEmail": "Invalid e-mail",
        "userNotFound": "This mail is not registered in the system",
        "disableServiceAccount": "User of tech. support cannot change the password",
        "alreadyRequested": "You have already requested a password change",
        "undefinedError": "Unknown error"
    }
}
</i18n>

<i18n locale="es_ES" lang="json">
{
    "title": "Restaurar el acceso a {siteName}",
    "receiveEmail": "Recibir un email de instrucciones",
    "backward": "Atrás",
    "error": {
        "emptyEmail": "Introduce tu correo electrónico",
        "invalidEmail": "Email no válido",
        "userNotFound": "Este correo no está registrado en el sistema",
        "disableServiceAccount": "Usuario de tecnología. soporte no puede cambiar la contraseña",
        "alreadyRequested": "Ya has solicitado un cambio de contraseña",
        "undefinedError": "Error desconocido"
    }
}
</i18n>

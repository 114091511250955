export class Account {
    public uid: string;
    public domain: string;
    public active: boolean;

    constructor(uid: string, domain: string, active: boolean) {
        this.uid = uid;
        this.domain = domain;
        this.active = active;
    }
}

import type { App } from 'vue';

let reCaptchaInstance: ReCaptchaInstanceInterface;

export function useReCaptcha() {
    return {
        reCaptcha: reCaptchaInstance,
        load,
    }
}

export interface ReCaptchaPluginOptions {
  siteKey: string;
}

export const ReCaptcha = {
    install(app: App, options: ReCaptchaPluginOptions) {
        reCaptchaInstance = new MockRecaptchaInstance()

        if (!options.siteKey) {
            return;
        }

        reCaptchaInstance = new ReCaptchaInstance(options)
    },
};

interface ReCaptchaInstanceInterface {
    execute(action: string): Promise<string>;
    load(): void
}

class MockRecaptchaInstance implements ReCaptchaInstanceInterface {
    execute(action: string): Promise<string> {
        return Promise.resolve(action);
    }

    load(): void {
        return
    }
}

class ReCaptchaInstance implements ReCaptchaInstanceInterface {
    private options: ReCaptchaPluginOptions
    private renderId = ''

    constructor(options: ReCaptchaPluginOptions) {
        this.options = options
    }

    execute(action: string): Promise<string> {
        if (!window.grecaptcha) {
            throw new Error('recaptcha not loaded');
        }

        return window.grecaptcha.execute(this.renderId, { action: action })
    }

    load() {
        if (!window.grecaptcha) {
            throw new Error('recaptcha not loaded');
        }

        this.renderId = window.grecaptcha.render({
            sitekey: this.options.siteKey,
        })
    }
}

const load = (): Promise<void> => {
    return new Promise<void>(resolve => {
        const doc: Document = document
        const script: HTMLScriptElement = doc.createElement('script')

        script.async = true
        script.src = 'https://www.google.com/recaptcha/api.js?render=explicit'

        doc.body.appendChild(script);

        script.onload = () => {
            wait(() => {
                reCaptchaInstance.load()

                resolve()
            })
        }
    })
}

const wait = (callback: () => void) => {
    if (window.grecaptcha === undefined) {
        setTimeout(() => {
            wait(callback);
        }, 25);
    } else {
        window.grecaptcha.ready(() => {
            callback();
        });
    }
}

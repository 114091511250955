import { defineStore } from 'pinia';
import { ref } from 'vue';
import client from '@/api/public/client';

export const usePasswordStore = defineStore('password', () => {
    const makeRequestChangePassword = ref<boolean>(false);
    const makeRequestResetPassword = ref<boolean>(false);

    const fetchRequestPassword = (email: string, token: string) => {
        return client
            .requestPassword({
                email: email,
                recaptcha: token,
            })
            .then((response) => {
                if (response.data != null) {
                    makeRequestChangePassword.value = true;
                }

                return response;
            })
            .catch((error) => {
                throw error;
            });
    };

    const fetchResetPassword = (
        firstPassword: string,
        secondPassword: string,
        confirmationToken: string,
        token: string
    ) => {
        return client
            .resetPassword({
                firstPassword: firstPassword,
                secondPassword: secondPassword,
                confirmationToken: confirmationToken,
                recaptcha: token,
            })
            .then((response) => {
                if (response.data != null) {
                    makeRequestResetPassword.value = true;
                }

                return response;
            })
            .catch((error) => {
                throw error;
            });
    };

    return {
        makeRequestChangePassword,
        makeRequestResetPassword,
        fetchRequestPassword,
        fetchResetPassword,
    };
});

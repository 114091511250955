import type {
    AccountsRequest,
    AccountsResponse, ChangeEmailRequest, ChangePasswordRequest,
    ClientInterface,
    CodeResponse, EmailChangeResponse,
    MeResponse,
} from '@/api/private/interface';
import client from '@/api/client';
import type { Response } from '@/api/response';
import { handleError } from '@/api/response';
import type { SuccessResponse } from '../auth/interface';

class Client implements ClientInterface {
    me(): Promise<Response<MeResponse>> {
        return new Promise<Response<MeResponse>>((resolve, reject) => {
            client
                .get<MeResponse>('/api/v1/client/me')
                .then(function (response) {
                    resolve({
                        data: response.data,
                    });
                })
                .catch(function (err) {
                    handleError(err, resolve, reject);
                });
        });
    }

    accounts(request: AccountsRequest): Promise<Response<AccountsResponse>> {
        return new Promise<Response<AccountsResponse>>((resolve, reject) => {
            client
                .get<AccountsResponse>('/api/v1/client/accounts', {
                    params: {
                        first: request.first,
                        last: request.last,
                        before: request.before,
                        after: request.after,
                        filter: {
                            domain: request.domain,
                        },
                    },
                })
                .then(function (response) {
                    resolve({
                        data: response.data,
                    });
                })
                .catch(function (err) {
                    handleError(err, resolve, reject);
                });
        });
    }

    code(uid: string): Promise<Response<CodeResponse>> {
        return new Promise<Response<CodeResponse>>((resolve, reject) => {
            client
                .get<CodeResponse>(`/api/v1/client/${uid}/code`)
                .then(function (response) {
                    resolve({
                        data: response.data,
                    });
                })
                .catch(function (err) {
                    handleError(err, resolve, reject);
                });
        });
    }

    changePassword(request: ChangePasswordRequest) {
        return new Promise<Response<SuccessResponse>>((resolve, reject) => {
            client
                .patch<SuccessResponse>('/api/v1/client/account/password', {
                    old_password: request.oldPassword,
                    new_password: request.newPassword,
                    conf_password: request.confPassword,
                })
                .then(resolve)
                .catch((err) => handleError(err, resolve, reject));
        });
    }

    changeEmail(request: ChangeEmailRequest) {
        return new Promise<Response<EmailChangeResponse>>(((resolve, reject) => {
            client
                .patch<EmailChangeResponse>('/api/v1/client/account/email', request)
                .then(resolve)
                .catch((err) => handleError(err, resolve, reject))
        }))
    }
}

export default new Client();

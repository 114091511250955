import type { App } from 'vue';
import type { Router } from 'vue-router';

let metrikaInstance: MetrikaInstanceInterface;

export function useMetrika(): MetrikaInstanceInterface {
    return metrikaInstance;
}

export interface MetrikaPluginOptions {
    id: string;
    vueRouter?: Router;
}

export const Metrika = {
    install: function(app: App, options: MetrikaPluginOptions) {
        metrikaInstance = new MockMetrikaInstance()

        if (options.vueRouter) {
            initVueRouterGuard(
                app,
                options.vueRouter
            );
        }

        if (!options.id) {
            return;
        }

        loadScript()
            .then(() => {
                metrikaInstance = new Ya.Metrika2({
                    id: options.id,
                    clickmap:true,
                    trackLinks:true,
                    accurateTrackBounce:true,
                    webvisor:true,
                    trackHash:true,
                })
            });
    },
};

function initVueRouterGuard(
    app: App,
    vueRouter: Exclude<MetrikaPluginOptions['vueRouter'], undefined>
): void {
    vueRouter.afterEach(async (to, from) => {
        if (to.path == from.path) {
            return;
        }

        useMetrika().hit(to.path);
    });
}

function loadScript(): Promise<void> {
    return new Promise<void>(resolve => {
        const doc: Document = document
        const script: HTMLScriptElement = doc.createElement('script')

        script.async = true
        script.src = 'https://mc.yandex.ru/metrika/tag.js'

        doc.body.appendChild(script);

        script.onload = () => resolve()
    })
}

interface MetrikaInstanceInterface {
    hit(url: string): void;
    reachGoal(target: string): void;
}

class MockMetrikaInstance implements MetrikaInstanceInterface {
    hit(): void {
        return
    }
    reachGoal(): void {
        return
    }
}

<template>
    <div
        :class="{
            [$style['auth-input']]: true,
            [$style['auth-input_active']]: modelValue || isFocus,
            [$style['auth-input_error']]: error,
        }"
    >
        <input
            :id="code"
            :value="modelValue"
            :name="name"
            :type="inputType"
            :disabled="disabled"
            placeholder="placeholder"
            :class="$style['auth-input__field']"
            autocomplete="off"
            @focus="onFocus"
            @blur="onBlur"
            @input="onInput"
        />
        <label :for="props.id" :class="$style['auth-input__label']">
            <slot name="label">{{ label }}</slot>
        </label>

        <div
            v-if="isPassword && (isFocus || modelValue)"
            :class="$style['auth-input__icon']"
            @click="showPassword = !showPassword"
        >
            <UiTooltip v-if="!showPassword" dark>
                <template #trigger>
                    <IconHiddenOutlined :class="$style['auth-input__icon_hide-eye']" />
                </template>
                {{ t("showPassword") }}
            </UiTooltip>
            <UiTooltip v-else dark>
                <template #trigger>
                    <IconVisibleOutlined :class="$style['auth-input__icon_hide-eye']" />
                </template>
                {{ t("hidePassword") }}
            </UiTooltip>
        </div>

        <PasswordComplexityTooltip
            v-if="withTooltip"
            :password="modelValue"
            :show="isFocus"
        />
        <UiError
            v-if="typeof error === 'string'"
            :message="props.error"
            :class="$style['auth-input__error']"
        />
    </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import PasswordComplexityTooltip from './PasswordComplexityTooltip.vue';
import IconVisibleOutlined from '@/components/icons/IconVisibleOutlined.vue';
import IconHiddenOutlined from '@/components/icons/IconHiddenOutlined.vue';

const { t } = useI18n();

const props = defineProps({
    id: {
        type: String,
        required: true,
        default: null,
    },

    name: {
        type: String,
        default: null,
    },

    modelValue: {
        type: [String, Number],
        default: '',
    },

    label: {
        type: [String],
        default: null,
    },

    type: {
        type: String,
        default: 'text',
    },

    disabled: {
        type: Boolean,
        default: false,
    },

    error: {
        type: [String, Boolean],
        default: false,
    },

    withTooltip: {
        type: Boolean,
        default: false,
    },
});

const emits = defineEmits([
    'focus',
    'blur',
    'input',
    'keydown',
    'paste',
    'update:modelValue',
]);

const isFocus = ref(false);
const showPassword = ref(false);
const isPassword = computed((): boolean => {
    return props.type === 'password';
});

const code = computed((): string => {
    return props.id;
});

const inputType = computed((): string => {
    if (isPassword.value && showPassword.value) {
        return 'text';
    }

    return props.type;
});

const onFocus = (e: Event): void => {
    isFocus.value = true;
    emits('focus', e);
};

const onBlur = (e: Event): void => {
    isFocus.value = false;
    emits('blur', e);
};

const onInput = (e: Event): void => {
    emits('update:modelValue', (e.target as HTMLInputElement).value);
    emits('input', e);
};
</script>

<style lang="less" module>
@import (reference) "../../assets/styles/variables.less";

.auth-input {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: @spacing-s;
  width: 100%;

  &__label {
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: top left;
    transform: translate(12px, 50%) scale(1);
    font-style: normal;
    color: @grey-900;
    padding: 0 6px;
    background-color: #fff;
    transition: all 0.25s;
    font-size: 16px;
    margin: 0;
    pointer-events: none;
  }

  &__icon {
    width: @spacing-m;
    height: @spacing-m;
    position: absolute;
    right: 10px;
    top: @spacing-m;
    transform: translateY(-50%);
    transition: opacity 0.25s;

    svg {
      width: @spacing-m;
      height: @spacing-m;
      fill: @grey-700;
      transition: fill 0.2s linear;

      &:hover {
        fill: @blue-500;
      }

      &,
      &:focus {
        outline: none;
      }
    }

    &_hide-eye {
      fill: @grey-700;
      width: 24px;
      height: 24px;
    }
  }

  &__field {
    background-color: #ffffff;
    border: 2px solid @grey-500;
    border-radius: @border-radius-m;
    font-size: @spacing-s;
    outline: none;
    height: @spacing-xxl;
    width: 100%;
    padding: 13px @spacing-s;
    transition: border-color 0.25s;
    color: @black-500;
    box-shadow: none;

    &:-webkit-autofill {
      animation-name: onAutoFillStart;
      transition: background-color 50000s ease-in-out 0s;
    }

    &::placeholder {
      color: transparent;
    }

    &:focus {
      border-color: @blue-500;
    }

    &:focus ~ .auth-input__label {
      color: @blue-500;
    }

    /* хак для firefox, убирает жёлтый фильтр при автозаполнении */
    &:not(:placeholder-shown) {
      filter: none;
    }

    &:focus ~ .auth-input__label,
    &:not(:placeholder-shown) ~ .auth-input__label {
      transform: translate(@spacing-s, -@spacing-xs) scale(0.75);
      z-index: 2;
    }

    &:not(:-ms-input-placeholder) ~ .auth-input__label {
      transform: translate(@spacing-s, -@spacing-xs) scale(0.75);
      z-index: 2;
    }
  }

  &__error {
    margin-top: @spacing-xs;
  }

  &_error {
    .auth-input__field {
      border-color: @red-500;
    }

    .auth-input__label,
    .auth-input__field:focus ~ .auth-input__label {
      color: @red-500;
    }
  }

  /** выносим в отдельный блок т.к.
    если браузер не поддерживает autofill,
    то при группировке селекторов стили не будут применяться
  **/
  &:-webkit-autofill ~ .auth-input__label {
    transform: translate(@spacing-s, -@spacing-xs) scale(0.75);
    z-index: 2;
  }

  &_active {
    .auth-input__icon {
      cursor: pointer;
      display: block;
      opacity: 1;
    }
  }
}
</style>

<i18n locale="ru_RU" lang="json">
{
  "showPassword": "Показать пароль",
  "hidePassword": "Скрыть пароль"
}
</i18n>

<i18n locale="en_GB" lang="json">
{
  "showPassword": "Show password",
  "hidePassword": "Hide password"
}
</i18n>

<i18n locale="es_ES" lang="json">
{
  "showPassword": "Mostrar la contraseña",
  "hidePassword": "Ocultar la contraseña"
}
</i18n>

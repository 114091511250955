import { computed, ref } from 'vue';

const code = ref<number | undefined>()

export function useError() {
    const setCode = (newCode: number) => {
        code.value = newCode
    }

    const resetCode = () => {
        code.value = undefined
    }

    const has500 = computed(() => {
        return code.value != undefined && code.value >= 500
    })

    const has404 = computed(() => {
        return code.value != undefined && code.value === 404
    })

    return {
        setCode,
        resetCode,
        has500,
        has404,
    }
}

import type { App } from 'vue';
import type { Router } from 'vue-router';

export interface GtmOptions {
    id: string;
    vueRouter?: Router;
}

let gtmPlugin: GtmPlugin;

export function useGtm(): GtmPlugin {
    return gtmPlugin;
}

export const Gtm = {
    install(app: App, options: GtmOptions) {
        gtmPlugin = new GtmPlugin();

        if (options.vueRouter) {
            initVueRouterGuard(
                app,
                options.vueRouter
            );
        }

        window.dataLayer = [];

        if (!options.id) {
            return;
        }

        loadScript(options.id);
    },
};

function initVueRouterGuard(
    app: App,
    vueRouter: Exclude<GtmOptions['vueRouter'], undefined>
): void {
    vueRouter.afterEach(async (to) => {
        if (typeof to.name !== 'string') {
            return;
        }

        const name: string = typeof to.meta.gtm === 'string' && !!to.meta.gtm
            ? to.meta.gtm
            : to.name;

        let fullUrl: string = vueRouter.options.history.base;
        if (!fullUrl.endsWith('/')) {
            fullUrl += '/';
        }

        fullUrl += to.fullPath.startsWith('/')
            ? to.fullPath.substring(1)
            : to.fullPath;

        gtmPlugin.trackView(name, fullUrl);
    });
}

function loadScript(id: string) {
    const doc: Document = document;
    const script: HTMLScriptElement = doc.createElement('script');

    window.dataLayer.push({
        event: 'gtm.js',
        'gtm.start': new Date().getTime(),
    });

    script.async = true;

    const queryString: URLSearchParams = new URLSearchParams({id});

    script.src = `https://www.googletagmanager.com/gtm.js?${queryString}`;

    doc.body.appendChild(script);
}

class GtmPlugin {
    public trackView(
        screenName: string,
        path: string
    ): void {
        window.dataLayer.push({
            event: 'content-view',
            'content-view-name': screenName,
            'content-name': path,
        });
    }

    public trackEvent(
        event: string,
        target: string | null = null,
        action: string | null = null,
        targetProperties: string | null = null,
        value: string | null = null,
        interactionType: boolean | null = false
    ): void {
        window.dataLayer.push({
            event: event,
            target: target,
            action: action,
            'target-properties': targetProperties,
            value: value,
            'interaction-type': interactionType,
        });
    }
}

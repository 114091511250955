import axios, { AxiosError } from 'axios';

export function handleError<T>(
    err: Error | AxiosError<ErrorResponse>,
    resolve: (data: Response<T>) => void,
    reject: (err: Error) => void
) {
    if (axios.isAxiosError(err) && err.response && err.response.status < 500) {
        return resolve({
            data: null,
            error: {
                code: err.response.status,
                messages: err.response.data.data.errors,
            },
        });
    } else {
        return reject(err);
    }
}

export interface Response<T> {
  data: T | null;
  error?: {
    code: number;
    messages: string[];
  };
}

export interface ErrorResponse {
  status: string;
  message: string;
  data: {
    errors: string[];
  };
}

<template>
    <LayoutBase>
        <template #title>{{ t("passwordChangeSuccess") }}</template>
        <template #description>
            {{ t("passwordChangeSuccessDescription") }}
        </template>

        <div class="row row_top-m">
            <UiButton size="lg" class="full-width" @click="toSignIn">
                {{ t("toSignIn") }}
            </UiButton>
        </div>
    </LayoutBase>
</template>

<script lang="ts" setup>
import LayoutBase from '@/components/layouts/LayoutBase.vue';

import { onBeforeMount } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import {useMeStore} from '@/stores/me';
import { useReCaptcha } from '@/plugins/reCaptcha';

const { t } = useI18n();
const { reCaptcha } = useReCaptcha();

const { logout } = useMeStore()

const router = useRouter();

onBeforeMount(async () => {
    await reCaptcha.execute('logout')
        .then(function (token: string) {
            return logout(token)
        })
});

const toSignIn = () => {
    router.push({ name: 'login' });
};
</script>

<style lang="less" scoped>
.full-width {
  width: 100%;
}
</style>

<i18n locale="ru_RU" lang="json">
{
  "passwordChangeSuccess": "Ваш\u00a0пароль\u00a0изменён!",
  "passwordChangeSuccessDescription": "Для входа в систему необходимо ввести новый пароль",
  "toSignIn": "Войти"
}
</i18n>

<i18n locale="en_GB" lang="json">
{
  "passwordChangeSuccess": "Your password has been changed!",
  "passwordChangeSuccessDescription": "You must enter a new password to sign in",
  "toSignIn": "Sign in"
}
</i18n>

<i18n locale="es_ES" lang="json">
{
  "passwordChangeSuccess": "La contraseña ha sido cambiada!",
  "passwordChangeSuccessDescription": "Para entrar en el sistema, tienes que ingresar nueva contraseña",
  "toSignIn": "Entrar"
}
</i18n>

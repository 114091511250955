import { createI18n } from 'vue-i18n';

import ru from '@/i18n/locales/ru_RU.json';
import en from '@/i18n/locales/en_GB.json';
import es from '@/i18n/locales/es_ES.json';

export enum LOCALES {
    RU = 'ru_RU',
    EN = 'en_GB',
    ES = 'es_ES',
}

const defineLocale = (): LOCALES => {
    let queryLang = new URLSearchParams(window.location.search).get('lang')
    queryLang = queryLang?.split('_').shift() ?? null

    if (queryLang !== null) {
        for (const variable in LOCALES) {
            if (variable === queryLang.toUpperCase()) {
                return LOCALES[variable as keyof typeof LOCALES]
            }
        }
    }

    for (const language of navigator.languages) {
        for (const variable in LOCALES) {
            if (variable.toLowerCase() === language.split('-').shift()) {
                return LOCALES[variable as keyof typeof LOCALES]
            }
        }
    }

    return LOCALES[import.meta.env.VITE_LANG as keyof typeof LOCALES]
}

export default createI18n({
    legacy: false,
    locale: defineLocale(),
    fallbackLocale: LOCALES.EN,
    messages: {
        [LOCALES.RU]: ru,
        [LOCALES.EN]: en,
        [LOCALES.ES]: es,
    },
});
